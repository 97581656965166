import * as React from "react";
import Layout from "../components/layout";
import SEO from "../components/seo";
import "twin.macro";

export default function dataprotection() {
  return (
    <Layout>
      <SEO title="Datenschutz" />

      <div
        className="fusion-column-wrapper"
        data-bg-url=""
        tw="flex flex-col justify-center text-center relative px-4 md:px-12"
      >
        <h1
          data-fontsize="54"
          data-lineheight="62.64px"
          tw="text-primary font-bold text-6xl mt-12 mb-24 md:mb-64"
        >
          Datenschutz
        </h1>
      </div>
      <div tw="prose lg:prose-xl px-8 text-black">
        <p>1. Datenschutz auf einen Blick</p>
        <p>Allgemeine Hinweise</p>
        <p>
          Die folgenden Hinweise geben einen einfachen Überblick darüber, was
          mit Ihren personenbezogenen Daten passiert, wenn Sie diese Website
          besuchen. Personenbezogene Daten sind alle Daten, mit denen Sie
          persönlich identifiziert werden können. Ausführliche Informationen zum
          Thema Datenschutz entnehmen Sie unserer unter diesem Text aufgeführten
          Datenschutzerklärung.
        </p>
        <p>Datenerfassung auf dieser Website</p>
        <p>Wer ist verantwortlich für die Datenerfassung auf dieser Website?</p>
        <p>
          Die Datenverarbeitung auf dieser Website erfolgt durch den
          Websitebetreiber. Dessen Kontaktdaten können Sie dem Impressum dieser
          Website entnehmen.
        </p>
        <p>Wie erfassen wir Ihre Daten?</p>
        <p>
          Ihre Daten werden zum einen dadurch erhoben, dass Sie uns diese
          mitteilen. Hierbei kann es sich z. B. um Daten handeln, die Sie in ein
          Kontaktformular eingeben.
        </p>
        <p>
          Andere Daten werden automatisch oder nach Ihrer Einwilligung beim
          Besuch der Website durch unsere ITSysteme erfasst. Das sind vor allem
          technische Daten (z. B. Internetbrowser, Betriebssystem oder Uhrzeit
          des Seitenaufrufs). Die Erfassung dieser Daten erfolgt automatisch,
          sobald Sie diese Website betreten.
        </p>
        <p>Wofür nutzen wir Ihre Daten?</p>
        <p>
          Ein Teil der Daten wird erhoben, um eine fehlerfreie Bereitstellung
          der Website zu gewährleisten. Andere Daten können zur Analyse Ihres
          Nutzerverhaltens verwendet werden.
        </p>
        <p>Welche Rechte haben Sie bezüglich Ihrer Daten?</p>
        <p>
          Sie haben jederzeit das Recht, unentgeltlich Auskunft über Herkunft,
          Empfänger und Zweck Ihrer
        </p>
        <p>
          gespeicherten personenbezogenen Daten zu erhalten. Sie haben außerdem
          ein Recht, die Berichtigung oder Löschung dieser Daten zu verlangen.
          Wenn Sie eine Einwilligung zur Datenverarbeitung erteilt haben, können
          Sie diese Einwilligung jederzeit für die Zukunft widerrufen. Außerdem
          haben Sie das Recht, unter bestimmten Umständen die Einschränkung der
          Verarbeitung Ihrer personenbezogenen Daten zu verlangen. Des Weiteren
          steht Ihnen ein Beschwerderecht bei der zuständigen Aufsichtsbehörde
          zu.
        </p>
        <p>
          Hierzu sowie zu weiteren Fragen zum Thema Datenschutz können Sie sich
          jederzeit unter der im Impressum angegebenen Adresse an uns wenden.
        </p>
        <p>2. Hosting und Content Delivery Networks (CDN)</p>
        <p>Externes Hosting</p>
        <p>
          Diese Website wird bei einem externen Dienstleister gehostet (Hoster).
          Die personenbezogenen Daten, die auf dieser Website erfasst werden,
          werden auf den Servern des Hosters gespeichert. Hierbei kann es sich
          v. a. um IP-Adressen, Kontaktanfragen, Meta- und Kommunikationsdaten,
          Vertragsdaten, Kontaktdaten, Namen, Websitezugriffe und sonstige
          Daten, die über eine Website generiert werden, handeln.
        </p>
        <p>
          Der Einsatz des Hosters erfolgt zum Zwecke der Vertragserfüllung
          gegenüber unseren potenziellen und bestehenden Kunden (Art. 6 Abs. 1
          lit. b DSGVO) und im Interesse einer sicheren, schnellen und
          effizienten Bereitstellung unseres Online-Angebots durch einen
          professionellen Anbieter (Art. 6 Abs. 1 lit. f DSGVO).
        </p>
        <p>
          Unser Hoster wird Ihre Daten nur insoweit verarbeiten, wie dies zur
          Erfüllung seiner Leistungspflichten erforderlich ist und unsere
          Weisungen in Bezug auf diese Daten befolgen.
        </p>
        <p>
          Für das Hosting unserer Webseite verwenden wir momentan die Dienste
          von https://www.netlify.com (Netlify, Inc., 2325 3rd Street, Suite
          215, San Francisco, 94107 CA). Die Server von Netlify befinden sich in
          den USA. Deshalb werden Ihre Daten, die für den Aufruf der Webseite
          benötigt werden (Zugriffsdaten), in die USA übermittelt.
          Rechtsgrundlage für die Übermittlung ist Art. 6 Abs. 1 S. 1 lit. f
          DSGVO. Um den Schutz Ihrer Persönlichkeitsrechte auch im Rahmen dieser
          Datenübertragungen zu gewährleisten, bedienen wir uns bei der
          Ausgestaltung der Vertragsverhältnisse mit Netlify der
          Standardvertragsklauseln der EU-Kommission gemäß Art. 46 Abs. 2 lit.
          c) DSGVO. Diese sind unter
          https://www.netlify.com/legal/netlify-dpa.pdf abrufbar, alternativ
          können Sie diese Dokumente auch bei uns unter den unten angegebenen
          Kontaktmöglichkeiten erfragen.
        </p>
        <p>
          Sie können gegen die Verarbeitung jederzeit Widerspruch einlegen.
          Beachten sie hierzu die Hinweise zu Ihrem Widerspruchsrecht unter
          Punkt 3 dieser Datenschutzerklärung. Sie können sich mit Ihrem
          Widerspruch sowohl an uns als auch direkt an Netlify wenden.
        </p>
        <p>Abschluss eines Vertrages über Auftragsverarbeitung</p>
        <p>
          Um die datenschutzkonforme Verarbeitung zu gewährleisten, haben wir
          einen Vertrag über Auftragsverarbeitung mit unserem Hoster
          geschlossen.
        </p>
        <p>3. Allgemeine Hinweise und Pflichtinformationen</p>
        <p>Datenschutz</p>
        <p>
          Die Betreiber dieser Seiten nehmen den Schutz Ihrer persönlichen Daten
          sehr ernst. Wir behandeln Ihre personenbezogenen Daten vertraulich und
          entsprechend der gesetzlichen Datenschutzvorschriften sowie dieser
          Datenschutzerklärung.
        </p>
        <p>
          Wenn Sie diese Website benutzen, werden verschiedene personenbezogene
          Daten erhoben.
        </p>
        <p>
          Personenbezogene Daten sind Daten, mit denen Sie persönlich
          identifiziert werden können. Die vorliegende Datenschutzerklärung
          erläutert, welche Daten wir erheben und wofür wir sie nutzen. Sie
          erläutert auch, wie und zu welchem Zweck das geschieht.
        </p>
        <p>
          Wir weisen darauf hin, dass die Datenübertragung im Internet (z. B.
          bei der Kommunikation per E-Mail) Sicherheitslücken aufweisen kann.
          Ein lückenloser Schutz der Daten vor dem Zugriff durch Dritte ist
          nicht möglich.
        </p>
        <p>Hinweis zur verantwortlichen Stelle</p>
        <p>
          Die verantwortliche Stelle für die Datenverarbeitung auf dieser
          Website ist:
        </p>
        <p>Werbeagentur Realdesign</p>
        <p>Sascha Hermann</p>
        <p>Gerhart-Hauptmann Str. 5 33613 Bielefeld</p>
        <p>Telefon: 052153725140</p>
        <p>E-Mail: info@realdesignteam.de</p>
        <p>
          Verantwortliche Stelle ist die natürliche oder juristische Person, die
          allein oder gemeinsam mit anderen über die Zwecke und Mittel der
          Verarbeitung von personenbezogenen Daten (z. B. Namen, E-Mail-Adressen
          o. Ä.) entscheidet.
        </p>
        <p>Widerruf Ihrer Einwilligung zur Datenverarbeitung</p>
        <p>
          Viele Datenverarbeitungsvorgänge sind nur mit Ihrer ausdrücklichen
          Einwilligung möglich. Sie können eine bereits erteilte Einwilligung
          jederzeit widerrufen. Die Rechtmäßigkeit der bis zum Widerruf
          erfolgten Datenverarbeitung bleibt vom Widerruf unberührt.
        </p>
        <p>
          Widerspruchsrecht gegen die Datenerhebung in besonderen Fällen sowie
          gegen Direktwerbung (Art. 21 DSGVO)
        </p>
        <p>
          WENN DIE DATENVERARBEITUNG AUF GRUNDLAGE VON ART. 6 ABS. 1 LIT. E ODER
          F DSGVO
        </p>
        <p>
          ERFOLGT, HABEN SIE JEDERZEIT DAS RECHT, AUS GRÜNDEN, DIE SICH AUS
          IHRER BESONDEREN
        </p>
        <p>
          SITUATION ERGEBEN, GEGEN DIE VERARBEITUNG IHRER PERSONENBEZOGENEN
          DATEN
        </p>
        <p>
          WIDERSPRUCH EINZULEGEN; DIES GILT AUCH FÜR EIN AUF DIESE BESTIMMUNGEN
          GESTÜTZTES
        </p>
        <p>
          PROFILING. DIE JEWEILIGE RECHTSGRUNDLAGE, AUF DENEN EINE VERARBEITUNG
          BERUHT,
        </p>
        <p>
          ENTNEHMEN SIE DIESER DATENSCHUTZERKLÄRUNG. WENN SIE WIDERSPRUCH
          EINLEGEN,
        </p>
        <p>
          WERDEN WIR IHRE BETROFFENEN PERSONENBEZOGENEN DATEN NICHT MEHR
          VERARBEITEN, ES
        </p>
        <p>
          SEI DENN, WIR KÖNNEN ZWINGENDE SCHUTZWÜRDIGE GRÜNDE FÜR DIE
          VERARBEITUNG
        </p>
        <p>
          NACHWEISEN, DIE IHRE INTERESSEN, RECHTE UND FREIHEITEN ÜBERWIEGEN ODER
          DIE VERARBEITUNG DIENT DER GELTENDMACHUNG, AUSÜBUNG ODER VERTEIDIGUNG
          VON RECHTSANSPRÜCHEN (WIDERSPRUCH NACH ART. 21 ABS. 1 DSGVO).
        </p>
        <p>
          WERDEN IHRE PERSONENBEZOGENEN DATEN VERARBEITET, UM DIREKTWERBUNG ZU
          BETREIBEN,
        </p>
        <p>
          SO HABEN SIE DAS RECHT, JEDERZEIT WIDERSPRUCH GEGEN DIE VERARBEITUNG
          SIE
        </p>
        <p>
          BETREFFENDER PERSONENBEZOGENER DATEN ZUM ZWECKE DERARTIGER WERBUNG
        </p>
        <p>
          EINZULEGEN; DIES GILT AUCH FÜR DAS PROFILING, SOWEIT ES MIT SOLCHER
          DIREKTWERBUNG IN
        </p>
        <p>
          VERBINDUNG STEHT. WENN SIE WIDERSPRECHEN, WERDEN IHRE
          PERSONENBEZOGENEN DATEN ANSCHLIESSEND NICHT MEHR ZUM ZWECKE DER
          DIREKTWERBUNG VERWENDET (WIDERSPRUCH NACH ART. 21 ABS. 2 DSGVO).
        </p>
        <p>Beschwerderecht bei der zuständigen Aufsichtsbehörde</p>
        <p>
          Im Falle von Verstößen gegen die DSGVO steht den Betroffenen ein
          Beschwerderecht bei einer
        </p>
        <p>
          Aufsichtsbehörde, insbesondere in dem Mitgliedstaat ihres gewöhnlichen
          Aufenthalts, ihres Arbeitsplatzes oder des Orts des mutmaßlichen
          Verstoßes zu. Das Beschwerderecht besteht unbeschadet anderweitiger
          verwaltungsrechtlicher oder gerichtlicher Rechtsbehelfe.
        </p>
        <p>Recht auf Datenübertragbarkeit</p>
        <p>
          Sie haben das Recht, Daten, die wir auf Grundlage Ihrer Einwilligung
          oder in Erfüllung eines Vertrags automatisiert verarbeiten, an sich
          oder an einen Dritten in einem gängigen, maschinenlesbaren Format
          aushändigen zu lassen. Sofern Sie die direkte Übertragung der Daten an
          einen anderen Verantwortlichen verlangen, erfolgt dies nur, soweit es
          technisch machbar ist.
        </p>
        <p>Auskunft, Löschung und Berichtigung</p>
        <p>
          Sie haben im Rahmen der geltenden gesetzlichen Bestimmungen jederzeit
          das Recht auf unentgeltliche
        </p>
        <p>
          Auskunft über Ihre gespeicherten personenbezogenen Daten, deren
          Herkunft und Empfänger und den Zweck der Datenverarbeitung und ggf.
          ein Recht auf Berichtigung oder Löschung dieser Daten. Hierzu sowie zu
          weiteren Fragen zum Thema personenbezogene Daten können Sie sich
          jederzeit unter der im Impressum angegebenen Adresse an uns wenden.
        </p>
        <p>Recht auf Einschränkung der Verarbeitung</p>
        <p>
          Sie haben das Recht, die Einschränkung der Verarbeitung Ihrer
          personenbezogenen Daten zu verlangen. Hierzu können Sie sich jederzeit
          unter der im Impressum angegebenen Adresse an uns wenden. Das Recht
          auf Einschränkung der Verarbeitung besteht in folgenden Fällen:
        </p>
        <p>
          {" "}
          Wenn Sie die Richtigkeit Ihrer bei uns gespeicherten personenbezogenen
          Daten bestreiten, benötigen wir
        </p>
        <p>
          in der Regel Zeit, um dies zu überprüfen. Für die Dauer der Prüfung
          haben Sie das Recht, die Einschränkung der Verarbeitung Ihrer
          personenbezogenen Daten zu verlangen.
        </p>
        <p>
          Wenn die Verarbeitung Ihrer personenbezogenen Daten unrechtmäßig
          geschah/geschieht, können Sie statt der Löschung die Einschränkung der
          Datenverarbeitung verlangen.
        </p>
        <p>
          Wenn wir Ihre personenbezogenen Daten nicht mehr benötigen, Sie sie
          jedoch zur Ausübung,
        </p>
        <p>
          Verteidigung oder Geltendmachung von Rechtsansprüchen benötigen, haben
          Sie das Recht, statt der Löschung die Einschränkung der Verarbeitung
          Ihrer personenbezogenen Daten zu verlangen.
        </p>
        <p>
          {" "}
          Wenn Sie einen Widerspruch nach Art. 21 Abs. 1 DSGVO eingelegt haben,
          muss eine Abwägung zwischen Ihren und unseren Interessen vorgenommen
          werden. Solange noch nicht feststeht, wessen Interessen überwiegen,
          haben Sie das Recht, die Einschränkung der Verarbeitung Ihrer
          personenbezogenen Daten zu verlangen.
        </p>
        <p>
          Wenn Sie die Verarbeitung Ihrer personenbezogenen Daten eingeschränkt
          haben, dürfen diese Daten – von ihrer Speicherung abgesehen – nur mit
          Ihrer Einwilligung oder zur Geltendmachung, Ausübung oder Verteidigung
          von Rechtsansprüchen oder zum Schutz der Rechte einer anderen
          natürlichen oder juristischen Person oder aus Gründen eines wichtigen
          öffentlichen Interesses der Europäischen Union oder eines
          Mitgliedstaats verarbeitet werden.
        </p>
        <p>Widerspruch gegen Werbe-E-Mails</p>
        <p>
          Der Nutzung von im Rahmen der Impressumspflicht veröffentlichten
          Kontaktdaten zur Übersendung von nicht ausdrücklich angeforderter
          Werbung und Informationsmaterialien wird hiermit widersprochen. Die
          Betreiber der Seiten behalten sich ausdrücklich rechtliche Schritte im
          Falle der unverlangten Zusendung von Werbeinformationen, etwa durch
          Spam-E-Mails, vor.
        </p>
        <p>4. Datenerfassung auf dieser Website</p>
        <p>Kontaktformular</p>
        <p>
          Wenn Sie uns per Kontaktformular Anfragen zukommen lassen, werden Ihre
          Angaben aus dem
        </p>
        <p>
          Anfrageformular inklusive der von Ihnen dort angegebenen Kontaktdaten
          zwecks Bearbeitung der Anfrage
        </p>
        <p>
          und für den Fall von Anschlussfragen bei uns gespeichert. Diese Daten
          geben wir nicht ohne Ihre Einwilligung weiter.
        </p>
        <p>
          Die Verarbeitung dieser Daten erfolgt auf Grundlage von Art. 6 Abs. 1
          lit. b DSGVO, sofern Ihre Anfrage mit der Erfüllung eines Vertrags
          zusammenhängt oder zur Durchführung vorvertraglicher Maßnahmen
          erforderlich ist. In allen übrigen Fällen beruht die Verarbeitung auf
          unserem berechtigten Interesse an der
        </p>
        <p>
          effektiven Bearbeitung der an uns gerichteten Anfragen (Art. 6 Abs. 1
          lit. f DSGVO) oder auf Ihrer Einwilligung (Art. 6 Abs. 1 lit. a DSGVO)
          sofern diese abgefragt wurde.
        </p>
        <p>
          Die von Ihnen im Kontaktformular eingegebenen Daten verbleiben bei
          uns, bis Sie uns zur Löschung auffordern, Ihre Einwilligung zur
          Speicherung widerrufen oder der Zweck für die Datenspeicherung
          entfällt
        </p>
        <p>
          (z. B. nach abgeschlossener Bearbeitung Ihrer Anfrage). Zwingende
          gesetzliche Bestimmungen – insbesondere Aufbewahrungsfristen – bleiben
          unberührt.
        </p>
        <p>Anfrage per E-Mail, Telefon oder Telefax</p>
        <p>
          Wenn Sie uns per E-Mail, Telefon oder Telefax kontaktieren, wird Ihre
          Anfrage inklusive aller daraus hervorgehenden personenbezogenen Daten
          (Name, Anfrage) zum Zwecke der Bearbeitung Ihres Anliegens bei uns
          gespeichert und verarbeitet. Diese Daten geben wir nicht ohne Ihre
          Einwilligung weiter.
        </p>
        <p>
          Die Verarbeitung dieser Daten erfolgt auf Grundlage von Art. 6 Abs. 1
          lit. b DSGVO, sofern Ihre Anfrage mit der Erfüllung eines Vertrags
          zusammenhängt oder zur Durchführung vorvertraglicher Maßnahmen
          erforderlich ist. In allen übrigen Fällen beruht die Verarbeitung auf
          unserem berechtigten Interesse an der
        </p>
        <p>
          effektiven Bearbeitung der an uns gerichteten Anfragen (Art. 6 Abs. 1
          lit. f DSGVO) oder auf Ihrer Einwilligung (Art. 6 Abs. 1 lit. a DSGVO)
          sofern diese abgefragt wurde.
        </p>
        <p>
          Die von Ihnen an uns per Kontaktanfragen übersandten Daten verbleiben
          bei uns, bis Sie uns zur Löschung auffordern, Ihre Einwilligung zur
          Speicherung widerrufen oder der Zweck für die Datenspeicherung
          entfällt
        </p>
        <p>
          (z. B. nach abgeschlossener Bearbeitung Ihres Anliegens). Zwingende
          gesetzliche Bestimmungen – insbesondere gesetzliche
          Aufbewahrungsfristen – bleiben unberührt.
        </p>
        <p>5. Soziale Medien</p>
        <p>Social-Media-Plugins mit Shariff</p>
        <p>
          Auf dieser Website werden Plugins von sozialen Medien verwendet (z. B.
          Facebook, Twitter, Instagram, Pinterest, XING, LinkedIn, Tumblr).
        </p>
        <p>
          Die Plugins können Sie in der Regel anhand der jeweiligen
          Social-Media-Logos erkennen. Um den
        </p>
        <p>
          Datenschutz auf dieser Website zu gewährleisten, verwenden wir diese
          Plugins nur zusammen mit der
        </p>
        <p>
          sogenannten „Shariff“-Lösung. Diese Anwendung verhindert, dass die auf
          dieser Website integrierten Plugins Daten schon beim ersten Betreten
          der Seite an den jeweiligen Anbieter übertragen.
        </p>
        <p>
          Erst wenn Sie das jeweilige Plugin durch Anklicken der zugehörigen
          Schaltfläche aktivieren, wird eine direkte Verbindung zum Server des
          Anbieters hergestellt (Einwilligung). Sobald Sie das Plugin
          aktivieren, erhält der jeweilige Anbieter die Information, dass Sie
          mit Ihrer IP-Adresse dieser Website besucht haben. Wenn Sie
          gleichzeitig in Ihrem jeweiligen Social-Media-Account (z. B. Facebook)
          eingeloggt sind, kann der jeweilige Anbieter den Besuch dieser Website
          Ihrem Benutzerkonto zuordnen.
        </p>
        <p>
          Das Aktivieren des Plugins stellt eine Einwilligung im Sinne des Art.
          6 Abs. 1 lit. a DSGVO dar. Diese Einwilligung können Sie jederzeit mit
          Wirkung für die Zukunft widerrufen.
        </p>
        <p>6. Plugins und Tools</p>
        <p>YouTube mit erweitertem Datenschutz</p>
        <p>
          Diese Website bindet Videos der YouTube ein. Betreiber der Seiten ist
          die Google Ireland Limited („Google“), Gordon House, Barrow Street,
          Dublin 4, Irland.
        </p>
        <p>
          Wir nutzen YouTube im erweiterten Datenschutzmodus. Dieser Modus
          bewirkt laut YouTube, dass YouTube keine Informationen über die
          Besucher auf dieser Website speichert, bevor diese sich das Video
          ansehen. Die Weitergabe von Daten an YouTube-Partner wird durch den
          erweiterten Datenschutzmodus hingegen nicht zwingend ausgeschlossen.
          So stellt YouTube – unabhängig davon, ob Sie sich ein Video ansehen –
          eine Verbindung zum Google DoubleClick-Netzwerk her.
        </p>
        <p>
          Sobald Sie ein YouTube-Video auf dieser Website starten, wird eine
          Verbindung zu den Servern von
        </p>
        <p>
          YouTube hergestellt. Dabei wird dem YouTube-Server mitgeteilt, welche
          unserer Seiten Sie besucht haben.
        </p>
        <p>
          Wenn Sie in Ihrem YouTube-Account eingeloggt sind, ermöglichen Sie
          YouTube, Ihr Surfverhalten direkt Ihrem persönlichen Profil
          zuzuordnen. Dies können Sie verhindern, indem Sie sich aus Ihrem
          YouTubeAccount ausloggen.
        </p>
        <p>
          Des Weiteren kann YouTube nach Starten eines Videos verschiedene
          Cookies auf Ihrem Endgerät speichern oder vergleichbare
          Wiedererkennungstechnologien (z.B. Device-Fingerprinting) einsetzen.
          Auf diese Weise kann YouTube Informationen über Besucher dieser
          Website erhalten. Diese Informationen werden u. a.
        </p>
        <p>
          verwendet, um Videostatistiken zu erfassen, die Anwenderfreundlichkeit
          zu verbessern und Betrugsversuchen vorzubeugen.
        </p>
        <p>
          Gegebenenfalls können nach dem Start eines YouTube-Videos weitere
          Datenverarbeitungsvorgänge ausgelöst werden, auf die wir keinen
          Einfluss haben.
        </p>
        <p>
          Die Nutzung von YouTube erfolgt im Interesse einer ansprechenden
          Darstellung unserer Online-Angebote.
        </p>
        <p>
          Dies stellt ein berechtigtes Interesse im Sinne von Art. 6 Abs. 1 lit.
          f DSGVO dar. Sofern eine entsprechende Einwilligung abgefragt wurde,
          erfolgt die Verarbeitung ausschließlich auf Grundlage von Art. 6 Abs.
          1 lit. a DSGVO; die Einwilligung ist jederzeit widerrufbar.
        </p>
        <p>
          Weitere Informationen über Datenschutz bei YouTube finden Sie in deren
          Datenschutzerklärung unter: https://policies.google.com/privacy?hl=de.
        </p>
        <p>
          Quelle: eRecht24
          <span>Datenschutzerklärung</span>
        </p>
        <p>1. Datenschutz auf einen Blick</p>
        <p>Allgemeine Hinweise</p>
        <p>
          Die folgenden Hinweise geben einen einfachen Überblick darüber, was
          mit Ihren personenbezogenen Daten passiert, wenn Sie diese Website
          besuchen. Personenbezogene Daten sind alle Daten, mit denen Sie
          persönlich identifiziert werden können. Ausführliche Informationen zum
          Thema Datenschutz entnehmen Sie unserer unter diesem Text aufgeführten
          Datenschutzerklärung.
        </p>
        <p>Datenerfassung auf dieser Website</p>
        <p>Wer ist verantwortlich für die Datenerfassung auf dieser Website?</p>
        <p>
          Die Datenverarbeitung auf dieser Website erfolgt durch den
          Websitebetreiber. Dessen Kontaktdaten können Sie dem Impressum dieser
          Website entnehmen.
        </p>
        <p>Wie erfassen wir Ihre Daten?</p>
        <p>
          Ihre Daten werden zum einen dadurch erhoben, dass Sie uns diese
          mitteilen. Hierbei kann es sich z. B. um Daten handeln, die Sie in ein
          Kontaktformular eingeben.
        </p>
        <p>
          Andere Daten werden automatisch oder nach Ihrer Einwilligung beim
          Besuch der Website durch unsere ITSysteme erfasst. Das sind vor allem
          technische Daten (z. B. Internetbrowser, Betriebssystem oder Uhrzeit
          des Seitenaufrufs). Die Erfassung dieser Daten erfolgt automatisch,
          sobald Sie diese Website betreten.
        </p>
        <p>Wofür nutzen wir Ihre Daten?</p>
        <p>
          Ein Teil der Daten wird erhoben, um eine fehlerfreie Bereitstellung
          der Website zu gewährleisten. Andere Daten können zur Analyse Ihres
          Nutzerverhaltens verwendet werden.
        </p>
        <p>Welche Rechte haben Sie bezüglich Ihrer Daten?</p>
        <p>
          Sie haben jederzeit das Recht, unentgeltlich Auskunft über Herkunft,
          Empfänger und Zweck Ihrer
        </p>
        <p>
          gespeicherten personenbezogenen Daten zu erhalten. Sie haben außerdem
          ein Recht, die Berichtigung oder Löschung dieser Daten zu verlangen.
          Wenn Sie eine Einwilligung zur Datenverarbeitung erteilt haben, können
          Sie diese Einwilligung jederzeit für die Zukunft widerrufen. Außerdem
          haben Sie das Recht, unter bestimmten Umständen die Einschränkung der
          Verarbeitung Ihrer personenbezogenen Daten zu verlangen. Des Weiteren
          steht Ihnen ein Beschwerderecht bei der zuständigen Aufsichtsbehörde
          zu.
        </p>
        <p>
          Hierzu sowie zu weiteren Fragen zum Thema Datenschutz können Sie sich
          jederzeit unter der im Impressum angegebenen Adresse an uns wenden.
        </p>
        <p>2. Hosting und Content Delivery Networks (CDN)</p>
        <p>Externes Hosting</p>
        <p>
          Diese Website wird bei einem externen Dienstleister gehostet (Hoster).
          Die personenbezogenen Daten, die auf dieser Website erfasst werden,
          werden auf den Servern des Hosters gespeichert. Hierbei kann es sich
          v. a. um IP-Adressen, Kontaktanfragen, Meta- und Kommunikationsdaten,
          Vertragsdaten, Kontaktdaten, Namen, Websitezugriffe und sonstige
          Daten, die über eine Website generiert werden, handeln.
        </p>
        <p>
          Der Einsatz des Hosters erfolgt zum Zwecke der Vertragserfüllung
          gegenüber unseren potenziellen und bestehenden Kunden (Art. 6 Abs. 1
          lit. b DSGVO) und im Interesse einer sicheren, schnellen und
          effizienten Bereitstellung unseres Online-Angebots durch einen
          professionellen Anbieter (Art. 6 Abs. 1 lit. f DSGVO).
        </p>
        <p>
          Unser Hoster wird Ihre Daten nur insoweit verarbeiten, wie dies zur
          Erfüllung seiner Leistungspflichten erforderlich ist und unsere
          Weisungen in Bezug auf diese Daten befolgen.
        </p>
        <p>Abschluss eines Vertrages über Auftragsverarbeitung</p>
        <p>
          Um die datenschutzkonforme Verarbeitung zu gewährleisten, haben wir
          einen Vertrag über Auftragsverarbeitung mit unserem Hoster
          geschlossen.
        </p>
        <p>3. Allgemeine Hinweise und Pflichtinformationen</p>
        <p>Datenschutz</p>
        <p>
          Die Betreiber dieser Seiten nehmen den Schutz Ihrer persönlichen Daten
          sehr ernst. Wir behandeln Ihre personenbezogenen Daten vertraulich und
          entsprechend der gesetzlichen Datenschutzvorschriften sowie dieser
          Datenschutzerklärung.
        </p>
        <p>
          Wenn Sie diese Website benutzen, werden verschiedene personenbezogene
          Daten erhoben.
        </p>
        <p>
          Personenbezogene Daten sind Daten, mit denen Sie persönlich
          identifiziert werden können. Die vorliegende Datenschutzerklärung
          erläutert, welche Daten wir erheben und wofür wir sie nutzen. Sie
          erläutert auch, wie und zu welchem Zweck das geschieht.
        </p>
        <p>
          Wir weisen darauf hin, dass die Datenübertragung im Internet (z. B.
          bei der Kommunikation per E-Mail) Sicherheitslücken aufweisen kann.
          Ein lückenloser Schutz der Daten vor dem Zugriff durch Dritte ist
          nicht möglich.
        </p>
        <p>Hinweis zur verantwortlichen Stelle</p>
        <p>
          Die verantwortliche Stelle für die Datenverarbeitung auf dieser
          Website ist:
        </p>
        <p>Werbeagentur Realdesign</p>
        <p>Sascha Hermann</p>
        <p>Gerhart-Hauptmann Str. 5 33613 Bielefeld</p>
        <p>Telefon: 052153725140</p>
        <p>E-Mail: info@realdesignteam.de</p>
        <p>
          Verantwortliche Stelle ist die natürliche oder juristische Person, die
          allein oder gemeinsam mit anderen über die Zwecke und Mittel der
          Verarbeitung von personenbezogenen Daten (z. B. Namen, E-Mail-Adressen
          o. Ä.) entscheidet.
        </p>
        <p>Widerruf Ihrer Einwilligung zur Datenverarbeitung</p>
        <p>
          Viele Datenverarbeitungsvorgänge sind nur mit Ihrer ausdrücklichen
          Einwilligung möglich. Sie können eine bereits erteilte Einwilligung
          jederzeit widerrufen. Die Rechtmäßigkeit der bis zum Widerruf
          erfolgten Datenverarbeitung bleibt vom Widerruf unberührt.
        </p>
        <p>
          Widerspruchsrecht gegen die Datenerhebung in besonderen Fällen sowie
          gegen Direktwerbung (Art. 21 DSGVO)
        </p>
        <p>
          WENN DIE DATENVERARBEITUNG AUF GRUNDLAGE VON ART. 6 ABS. 1 LIT. E ODER
          F DSGVO
        </p>
        <p>
          ERFOLGT, HABEN SIE JEDERZEIT DAS RECHT, AUS GRÜNDEN, DIE SICH AUS
          IHRER BESONDEREN
        </p>
        <p>
          SITUATION ERGEBEN, GEGEN DIE VERARBEITUNG IHRER PERSONENBEZOGENEN
          DATEN
        </p>
        <p>
          WIDERSPRUCH EINZULEGEN; DIES GILT AUCH FÜR EIN AUF DIESE BESTIMMUNGEN
          GESTÜTZTES
        </p>
        <p>
          PROFILING. DIE JEWEILIGE RECHTSGRUNDLAGE, AUF DENEN EINE VERARBEITUNG
          BERUHT,
        </p>
        <p>
          ENTNEHMEN SIE DIESER DATENSCHUTZERKLÄRUNG. WENN SIE WIDERSPRUCH
          EINLEGEN,
        </p>
        <p>
          WERDEN WIR IHRE BETROFFENEN PERSONENBEZOGENEN DATEN NICHT MEHR
          VERARBEITEN, ES
        </p>
        <p>
          SEI DENN, WIR KÖNNEN ZWINGENDE SCHUTZWÜRDIGE GRÜNDE FÜR DIE
          VERARBEITUNG
        </p>
        <p>
          NACHWEISEN, DIE IHRE INTERESSEN, RECHTE UND FREIHEITEN ÜBERWIEGEN ODER
          DIE VERARBEITUNG DIENT DER GELTENDMACHUNG, AUSÜBUNG ODER VERTEIDIGUNG
          VON RECHTSANSPRÜCHEN (WIDERSPRUCH NACH ART. 21 ABS. 1 DSGVO).
        </p>
        <p>
          WERDEN IHRE PERSONENBEZOGENEN DATEN VERARBEITET, UM DIREKTWERBUNG ZU
          BETREIBEN,
        </p>
        <p>
          SO HABEN SIE DAS RECHT, JEDERZEIT WIDERSPRUCH GEGEN DIE VERARBEITUNG
          SIE
        </p>
        <p>
          BETREFFENDER PERSONENBEZOGENER DATEN ZUM ZWECKE DERARTIGER WERBUNG
        </p>
        <p>
          EINZULEGEN; DIES GILT AUCH FÜR DAS PROFILING, SOWEIT ES MIT SOLCHER
          DIREKTWERBUNG IN
        </p>
        <p>
          VERBINDUNG STEHT. WENN SIE WIDERSPRECHEN, WERDEN IHRE
          PERSONENBEZOGENEN DATEN ANSCHLIESSEND NICHT MEHR ZUM ZWECKE DER
          DIREKTWERBUNG VERWENDET (WIDERSPRUCH NACH ART. 21 ABS. 2 DSGVO).
        </p>
        <p>Beschwerderecht bei der zuständigen Aufsichtsbehörde</p>
        <p>
          Im Falle von Verstößen gegen die DSGVO steht den Betroffenen ein
          Beschwerderecht bei einer
        </p>
        <p>
          Aufsichtsbehörde, insbesondere in dem Mitgliedstaat ihres gewöhnlichen
          Aufenthalts, ihres Arbeitsplatzes oder des Orts des mutmaßlichen
          Verstoßes zu. Das Beschwerderecht besteht unbeschadet anderweitiger
          verwaltungsrechtlicher oder gerichtlicher Rechtsbehelfe.
        </p>
        <p>Recht auf Datenübertragbarkeit</p>
        <p>
          Sie haben das Recht, Daten, die wir auf Grundlage Ihrer Einwilligung
          oder in Erfüllung eines Vertrags automatisiert verarbeiten, an sich
          oder an einen Dritten in einem gängigen, maschinenlesbaren Format
          aushändigen zu lassen. Sofern Sie die direkte Übertragung der Daten an
          einen anderen Verantwortlichen verlangen, erfolgt dies nur, soweit es
          technisch machbar ist.
        </p>
        <p>Auskunft, Löschung und Berichtigung</p>
        <p>
          Sie haben im Rahmen der geltenden gesetzlichen Bestimmungen jederzeit
          das Recht auf unentgeltliche
        </p>
        <p>
          Auskunft über Ihre gespeicherten personenbezogenen Daten, deren
          Herkunft und Empfänger und den Zweck der Datenverarbeitung und ggf.
          ein Recht auf Berichtigung oder Löschung dieser Daten. Hierzu sowie zu
          weiteren Fragen zum Thema personenbezogene Daten können Sie sich
          jederzeit unter der im Impressum angegebenen Adresse an uns wenden.
        </p>
        <p>Recht auf Einschränkung der Verarbeitung</p>
        <p>
          Sie haben das Recht, die Einschränkung der Verarbeitung Ihrer
          personenbezogenen Daten zu verlangen. Hierzu können Sie sich jederzeit
          unter der im Impressum angegebenen Adresse an uns wenden. Das Recht
          auf Einschränkung der Verarbeitung besteht in folgenden Fällen:
        </p>
        <p>
          {" "}
          Wenn Sie die Richtigkeit Ihrer bei uns gespeicherten personenbezogenen
          Daten bestreiten, benötigen wir
        </p>
        <p>
          in der Regel Zeit, um dies zu überprüfen. Für die Dauer der Prüfung
          haben Sie das Recht, die Einschränkung der Verarbeitung Ihrer
          personenbezogenen Daten zu verlangen.
        </p>
        <p>
          Wenn die Verarbeitung Ihrer personenbezogenen Daten unrechtmäßig
          geschah/geschieht, können Sie statt der Löschung die Einschränkung der
          Datenverarbeitung verlangen.
        </p>
        <p>
          Wenn wir Ihre personenbezogenen Daten nicht mehr benötigen, Sie sie
          jedoch zur Ausübung,
        </p>
        <p>
          Verteidigung oder Geltendmachung von Rechtsansprüchen benötigen, haben
          Sie das Recht, statt der Löschung die Einschränkung der Verarbeitung
          Ihrer personenbezogenen Daten zu verlangen.
        </p>
        <p>
          {" "}
          Wenn Sie einen Widerspruch nach Art. 21 Abs. 1 DSGVO eingelegt haben,
          muss eine Abwägung zwischen Ihren und unseren Interessen vorgenommen
          werden. Solange noch nicht feststeht, wessen Interessen überwiegen,
          haben Sie das Recht, die Einschränkung der Verarbeitung Ihrer
          personenbezogenen Daten zu verlangen.
        </p>
        <p>
          Wenn Sie die Verarbeitung Ihrer personenbezogenen Daten eingeschränkt
          haben, dürfen diese Daten – von ihrer Speicherung abgesehen – nur mit
          Ihrer Einwilligung oder zur Geltendmachung, Ausübung oder Verteidigung
          von Rechtsansprüchen oder zum Schutz der Rechte einer anderen
          natürlichen oder juristischen Person oder aus Gründen eines wichtigen
          öffentlichen Interesses der Europäischen Union oder eines
          Mitgliedstaats verarbeitet werden.
        </p>
        <p>Widerspruch gegen Werbe-E-Mails</p>
        <p>
          Der Nutzung von im Rahmen der Impressumspflicht veröffentlichten
          Kontaktdaten zur Übersendung von nicht ausdrücklich angeforderter
          Werbung und Informationsmaterialien wird hiermit widersprochen. Die
          Betreiber der Seiten behalten sich ausdrücklich rechtliche Schritte im
          Falle der unverlangten Zusendung von Werbeinformationen, etwa durch
          Spam-E-Mails, vor.
        </p>
        <p>4. Datenerfassung auf dieser Website</p>
        <p>Kontaktformular</p>
        <p>
          Wenn Sie uns per Kontaktformular Anfragen zukommen lassen, werden Ihre
          Angaben aus dem
        </p>
        <p>
          Anfrageformular inklusive der von Ihnen dort angegebenen Kontaktdaten
          zwecks Bearbeitung der Anfrage
        </p>
        <p>
          und für den Fall von Anschlussfragen bei uns gespeichert. Diese Daten
          geben wir nicht ohne Ihre Einwilligung weiter.
        </p>
        <p>
          Die Verarbeitung dieser Daten erfolgt auf Grundlage von Art. 6 Abs. 1
          lit. b DSGVO, sofern Ihre Anfrage mit der Erfüllung eines Vertrags
          zusammenhängt oder zur Durchführung vorvertraglicher Maßnahmen
          erforderlich ist. In allen übrigen Fällen beruht die Verarbeitung auf
          unserem berechtigten Interesse an der
        </p>
        <p>
          effektiven Bearbeitung der an uns gerichteten Anfragen (Art. 6 Abs. 1
          lit. f DSGVO) oder auf Ihrer Einwilligung (Art. 6 Abs. 1 lit. a DSGVO)
          sofern diese abgefragt wurde.
        </p>
        <p>
          Die von Ihnen im Kontaktformular eingegebenen Daten verbleiben bei
          uns, bis Sie uns zur Löschung auffordern, Ihre Einwilligung zur
          Speicherung widerrufen oder der Zweck für die Datenspeicherung
          entfällt
        </p>
        <p>
          (z. B. nach abgeschlossener Bearbeitung Ihrer Anfrage). Zwingende
          gesetzliche Bestimmungen – insbesondere Aufbewahrungsfristen – bleiben
          unberührt.
        </p>
        <p>Anfrage per E-Mail, Telefon oder Telefax</p>
        <p>
          Wenn Sie uns per E-Mail, Telefon oder Telefax kontaktieren, wird Ihre
          Anfrage inklusive aller daraus hervorgehenden personenbezogenen Daten
          (Name, Anfrage) zum Zwecke der Bearbeitung Ihres Anliegens bei uns
          gespeichert und verarbeitet. Diese Daten geben wir nicht ohne Ihre
          Einwilligung weiter.
        </p>
        <p>
          Die Verarbeitung dieser Daten erfolgt auf Grundlage von Art. 6 Abs. 1
          lit. b DSGVO, sofern Ihre Anfrage mit der Erfüllung eines Vertrags
          zusammenhängt oder zur Durchführung vorvertraglicher Maßnahmen
          erforderlich ist. In allen übrigen Fällen beruht die Verarbeitung auf
          unserem berechtigten Interesse an der
        </p>
        <p>
          effektiven Bearbeitung der an uns gerichteten Anfragen (Art. 6 Abs. 1
          lit. f DSGVO) oder auf Ihrer Einwilligung (Art. 6 Abs. 1 lit. a DSGVO)
          sofern diese abgefragt wurde.
        </p>
        <p>
          Die von Ihnen an uns per Kontaktanfragen übersandten Daten verbleiben
          bei uns, bis Sie uns zur Löschung auffordern, Ihre Einwilligung zur
          Speicherung widerrufen oder der Zweck für die Datenspeicherung
          entfällt
        </p>
        <p>
          (z. B. nach abgeschlossener Bearbeitung Ihres Anliegens). Zwingende
          gesetzliche Bestimmungen – insbesondere gesetzliche
          Aufbewahrungsfristen – bleiben unberührt.
        </p>
        <p>5. Soziale Medien</p>
        <p>Social-Media-Plugins mit Shariff</p>
        <p>
          Auf dieser Website werden Plugins von sozialen Medien verwendet (z. B.
          Facebook, Twitter, Instagram, Pinterest, XING, LinkedIn, Tumblr).
        </p>
        <p>
          Die Plugins können Sie in der Regel anhand der jeweiligen
          Social-Media-Logos erkennen. Um den
        </p>
        <p>
          Datenschutz auf dieser Website zu gewährleisten, verwenden wir diese
          Plugins nur zusammen mit der
        </p>
        <p>
          sogenannten „Shariff“-Lösung. Diese Anwendung verhindert, dass die auf
          dieser Website integrierten Plugins Daten schon beim ersten Betreten
          der Seite an den jeweiligen Anbieter übertragen.
        </p>
        <p>
          Erst wenn Sie das jeweilige Plugin durch Anklicken der zugehörigen
          Schaltfläche aktivieren, wird eine direkte Verbindung zum Server des
          Anbieters hergestellt (Einwilligung). Sobald Sie das Plugin
          aktivieren, erhält der jeweilige Anbieter die Information, dass Sie
          mit Ihrer IP-Adresse dieser Website besucht haben. Wenn Sie
          gleichzeitig in Ihrem jeweiligen Social-Media-Account (z. B. Facebook)
          eingeloggt sind, kann der jeweilige Anbieter den Besuch dieser Website
          Ihrem Benutzerkonto zuordnen.
        </p>
        <p>
          Das Aktivieren des Plugins stellt eine Einwilligung im Sinne des Art.
          6 Abs. 1 lit. a DSGVO dar. Diese Einwilligung können Sie jederzeit mit
          Wirkung für die Zukunft widerrufen.
        </p>
        <p>6. Plugins und Tools</p>
        <p>YouTube mit erweitertem Datenschutz</p>
        <p>
          Diese Website bindet Videos der YouTube ein. Betreiber der Seiten ist
          die Google Ireland Limited („Google“), Gordon House, Barrow Street,
          Dublin 4, Irland.
        </p>
        <p>
          Wir nutzen YouTube im erweiterten Datenschutzmodus. Dieser Modus
          bewirkt laut YouTube, dass YouTube keine Informationen über die
          Besucher auf dieser Website speichert, bevor diese sich das Video
          ansehen. Die Weitergabe von Daten an YouTube-Partner wird durch den
          erweiterten Datenschutzmodus hingegen nicht zwingend ausgeschlossen.
          So stellt YouTube – unabhängig davon, ob Sie sich ein Video ansehen –
          eine Verbindung zum Google DoubleClick-Netzwerk her.
        </p>
        <p>
          Sobald Sie ein YouTube-Video auf dieser Website starten, wird eine
          Verbindung zu den Servern von
        </p>
        <p>
          YouTube hergestellt. Dabei wird dem YouTube-Server mitgeteilt, welche
          unserer Seiten Sie besucht haben.
        </p>
        <p>
          Wenn Sie in Ihrem YouTube-Account eingeloggt sind, ermöglichen Sie
          YouTube, Ihr Surfverhalten direkt Ihrem persönlichen Profil
          zuzuordnen. Dies können Sie verhindern, indem Sie sich aus Ihrem
          YouTubeAccount ausloggen.
        </p>
        <p>
          Des Weiteren kann YouTube nach Starten eines Videos verschiedene
          Cookies auf Ihrem Endgerät speichern oder vergleichbare
          Wiedererkennungstechnologien (z.B. Device-Fingerprinting) einsetzen.
          Auf diese Weise kann YouTube Informationen über Besucher dieser
          Website erhalten. Diese Informationen werden u. a.
        </p>
        <p>
          verwendet, um Videostatistiken zu erfassen, die Anwenderfreundlichkeit
          zu verbessern und Betrugsversuchen vorzubeugen.
        </p>
        <p>
          Gegebenenfalls können nach dem Start eines YouTube-Videos weitere
          Datenverarbeitungsvorgänge ausgelöst werden, auf die wir keinen
          Einfluss haben.
        </p>
        <p>
          Die Nutzung von YouTube erfolgt im Interesse einer ansprechenden
          Darstellung unserer Online-Angebote.
        </p>
        <p>
          Dies stellt ein berechtigtes Interesse im Sinne von Art. 6 Abs. 1 lit.
          f DSGVO dar. Sofern eine entsprechende Einwilligung abgefragt wurde,
          erfolgt die Verarbeitung ausschließlich auf Grundlage von Art. 6 Abs.
          1 lit. a DSGVO; die Einwilligung ist jederzeit widerrufbar.
        </p>
        <p>
          Weitere Informationen über Datenschutz bei YouTube finden Sie in deren
          Datenschutzerklärung unter: https://policies.google.com/privacy?hl=de.
        </p>
        <p>Quelle: eRecht24</p>
      </div>
    </Layout>
  );
}
